<template>
  <div class="class-wrap">
    <a-spin :spinning="loading" size="large">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="班级类型" prop="classType">
          <a-select v-model="form.classType" :disabled="disabledRole">
            <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="班课类型" prop="groupType" v-if="form.classType === 'GROUP'">
          <a-select v-model="form.groupType" :disabled="disabledRole">
            <a-select-option value="REGULAR">常规班课</a-select-option>
            <a-select-option value="CAMP">集训班课</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="班级课频" prop="frequency">
          <a-select v-model="form.frequency">
            <a-select-option v-for="item in 7" :key="item" :value="item"> 一周{{ item }}次 </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="允许自主选课" prop="allowSelfBooking" v-if="form.classType === 'GROUP'">
          <a-radio-group v-model="form.allowSelfBooking">
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="上课平台" prop="platform">
          <a-select v-model="form.platform" :disabled="isEditing">
            <a-select-option v-for="item in platformsArr" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="!!form.platform"
          ref="capacity"
          label="班级容量"
          prop="capacity"
          :rules="{
            required: true,
            message: form.platform !== 'TALK_CLOUD' ? '请输入班级容量(1-1000数字)' : '请输入班级容量(1-20000数字)',
            trigger: 'change',
            type: 'integer',
            min: 1,
            max: form.platform !== 'TALK_CLOUD' ? 1000 : 20000,
          }"
        >
          <a-input-number
            :disabled="disabledCapacity"
            v-model="form.capacity"
            style="width: 100%"
            :placeholder="form.platform !== 'TALK_CLOUD' ? '请输入班级容量(1-1000数字)' : '请输入班级容量(1-20000数字)'"
          />
        </a-form-model-item>
        <a-form-model-item ref="seatNum" label="上台人数" prop="data.seatNum" v-if="form.platform === 'CLASS_IN'">
          <a-input-number
            v-model="form.data.seatNum"
            style="width: 100%"
            placeholder="请输入上台人数(1-12数字)"
            @blur="
              () => {
                $refs.seatNum.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="时区" prop="timezone">
          <a-select
            v-model="form.timezone"
            option-filter-prop="children"
            :filter-option="filterOption"
            show-search
            :disabled="isEditing && !roles.includes('TEACHING_STAFF') && !roles.includes('TEACHING_STAFF_ADMIN')"
          >
            <a-select-option v-for="item in timezoneList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="课程" prop="courseId">
          <a-select v-model="form.courseId" option-filter-prop="children" :filter-option="filterOption" show-search>
            <a-select-option v-for="item in courseOptions" :key="item.uuid" :value="item.uuid">
              {{ item.courseName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="班主任老师" prop="ownerId">
          <a-select
            v-model="form.ownerId"
            option-filter-prop="children"
            :filter-option="filterOption"
            show-search
            @search="getHeadmaster"
            :disabled="disabledRole"
          >
            <a-select-option v-for="item in headmasterOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="授课语言" prop="teachingLanguage">
          <a-select
            v-model="form.teachingLanguage"
            option-filter-prop="children"
            :filter-option="filterOption"
            show-search
            allowClear
          >
            <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="班级名称"
          prop="className"
          :rules="{ required: subject === 'CHINESE', message: '请输入班级名称', trigger: 'blur' }"

        >
          <a-input v-model="form.className" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
          <a-popconfirm
            placement="top"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onSubmit"
            v-if="languageConfirm && !form.teachingLanguage"
          >
            <template slot="title">
              <p>请确认不用设置授课语言吗</p>
            </template>
            <a-button type="primary"> 提交 </a-button>
          </a-popconfirm>
          <a-button type="primary" @click="onSubmit" v-else> 提交 </a-button>

          <a-button style="margin-left: 10px" @click="resetForm"> 取消 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import storage from 'store';
import { postReq, putReq, getReq, loadScheduleOptions, getCourse } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import { languageOptions } from '@/utils/const';

export default {
  name: 'classEdit',
  data() {
    return {
      disabledCapacity: false,
      classAdminName: '',
      getDdlLabel,
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
      other: '',
      loading: false,
      roles: [],
      userInfo: {},
      languageOptions,
      form: {
        className: '',
        classType: null,
        groupType: 'REGULAR',
        capacity: undefined,
        courseId: null,
        timezone: 'Asia/Shanghai',
        classStatus: 'NORMAL',
        ownerId: null,
        platform: null,
        frequency: null,
        allowSelfBooking: null,
        teachingLanguage: null,
        teachingMethod: null,
        data: {
          seatNum: undefined,
        },
        language: null,
      },
      rules: {
        classType: [{ required: true, message: '请选择班级类型', trigger: 'change' }],
        groupType: [{ required: true, message: '请选择班课类型', trigger: 'change' }],
        courseId: [{ required: true, message: '请选择课程', trigger: 'change' }],
        capacity: [
          {
            required: true,
            message: '请输入班级容量(1-1000数字)',
            trigger: 'change',
            type: 'integer',
            min: 1,
            max: 1000,
          },
        ],
        'data.seatNum': [
          { required: true, message: '请输入上台人数(1-12数字)', trigger: 'change', type: 'integer', min: 1, max: 12 },
        ],
        timezone: [{ required: true, message: '请选择时区', trigger: 'change' }],
        ownerId: [{ required: true, message: '请选择班主任老师', trigger: 'change' }],
        platform: [{ required: true, message: '请选择上课平台', trigger: 'change' }],
        frequency: [{ required: true, message: '请选择班级课频', trigger: 'change' }],
        allowSelfBooking: [{ required: true, message: '请选择是否允许自主选课', trigger: 'change' }],
        teachingMethod: [{ required: true, message: '请选择班级授课方式', trigger: 'change' }],
      },
      courseOptions: [],
      scheduleOptions: {
        classType: [],
      },
      timezoneList: Object.entries(JSON.parse(localStorage.timezoneMaps || '{}')).map(([value, label]) => ({
        label,
        value,
      })),
      headmasterOptions: [],
      apiUrl: '/api/admin/scheduling/form_class/',
      classId: this.$route.query.id,
      isEditing: !!this.$route.query.id,
      disabledRole: false,
    };
  },
  computed: {
    platformsArr() {
      if (this.userInfo.roleArr.includes('CLASS_ADMIN')) {
        return [{ label: '悟空教室', value: 'TALK_CLOUD' }];
      }
      return [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ];
    },
    languageConfirm() {
      if (!this.form.courseId) return false;
      const data = this.courseOptions.find((item) => item.uuid === this.form.courseId);

      return data?.subjectType?.nodeContent?.subject === 'CHINESE' && this.form?.classType === 'PRIVATE';
    },
    subject() {
      const data = this.courseOptions.find((item) => item.uuid === this.form.courseId);

      return data?.subjectType?.nodeContent?.subject;
    },
  },
  created() {
    this.roles = storage.get('roles');
    this.userInfo = storage.get('userInfo');
    this.classAdminName = this.userInfo.fullName;
    this.getHeadmaster(this.$route.query.classAdminNameSub || this.classAdminName);

    if (this.roles.includes('X_WUKONG')) {
      this.form.classType = 'PRIVATE';
    }
    if (
      !this.roles.includes('TEACHING_STAFF') &&
      !this.roles.includes('TEACHING_STAFF_ADMIN') &&
      this.roles.includes('CLASS_ADMIN')
    ) {
      this.form.classType = 'PRIVATE';
      this.form.ownerId = this.userInfo.userID;

      this.disabledRole = true;
    }

    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });

    getCourse({ shelfStatus: ['ON'], frozen: false }).then((courseOptions) => {
      this.courseOptions = courseOptions;
    });

    this.getDetail();
  },
  mounted() {},
  methods: {
    getHeadmaster(name) {
      if (!name?.trim?.()) return;
      postReq('/api/account/ref/class_admin', { name }).then((res) => {
        this.headmasterOptions = res.data.content;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.classType === 'PRIVATE') {
            delete this.form.allowSelfBooking;
            delete this.form.groupType;
          }

          if (this.classId) {
            this.loading = true;
            putReq(`${this.apiUrl}${this.classId}`, this.form)
              .then((res) => {
                this.$message.success('提交成功!');
                history.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;

            postReq(this.apiUrl, this.form)
              .then((res) => {
                this.$message.success('提交成功!');
                history.go(-1);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      history.go(-1);
    },
    getDetail() {
      if (!this.classId) return;
      this.loading = true;
      getReq(`${this.apiUrl}${this.classId}`, {})
        .then((res) => {
          const { content } = res.data;
          for (const key in content) {
            this.form[key] = content[key];
          }
          if (content.data) {
            for (const key in content.data) {
              this.form[key] = content.data[key];
            }
          }

          if (this.form.capacity === 1) {
            this.disabledCapacity = true;
          } else {
            this.disabledCapacity = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.class-wrap {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: #fff;
  padding: 40px 20px;
}
</style>
