// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts';
import {
  newstudent,
  chat,
  schedule,
  manage,
  task,
  personalCenter,
  msg,
  student,
  wukong,
  organization,
  task_a,
  newstudent_a,
  chat_a,
  schedule_a,
  manage_a,
  customer_a,
  customer,
  statistics_a,
  statistics,
  homepage,
  homepage_a,
  order,
  order_a,
  setting,
  setting_a,
} from '@/core/icons';

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
};

export const asyncRouterMapTeacher = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'service' },
    
    children: [
      {
        path: '/homeIndex',
        name: 'homeIndex',
        component: RouteView,
        redirect: '/homeIndex',
        hideChildrenInMenu: true,
        meta: {
          title: '主页',
          unSelectIcon: homepage,
          selectedIcon: homepage_a,
          permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
          
        },
        children: [
          {
            path: '/homeIndex',
            name: 'homeIndex',
            component: () => import('@/views/homeIndex/home'),
            meta: {
              title: '主页',
              cnMenuName: '主页',
              pMenuName: 'homeIndex',
              icon: homepage,
              permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
              menu: [
                {
                  path: '/homeIndex',
                  title: '主页',
                },
              ],
            },
          },
          {
            path: '/homeIndex/homeWorkList',
            name: 'homeWorkList',
            component: () => import('@/views/homeIndex/homeWorkList'),
            meta: {
              title: '作业列表',
              cnMenuName: '主页',
              pMenuName: 'homeIndex',
              icon: homepage,
              permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],

              menu: [
                {
                  path: '/homeIndex',
                  title: '主页',
                },
                {
                  path: '/homeIndex/homeWorkList',
                  title: '作业列表',
                },
              ],
            },
          },
          {
            path: '/app-react/task-student',
            name: 'taskStudent',
            component: () => import('@/views/microContainer/'),
            meta: {
              title: '任务学员',
              cnMenuName: '主页',
              pMenuName: 'homeIndex',
              icon: homepage,
              permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
              menu: [
                {
                  path: '/homeIndex',
                  title: '主页',
                },
                {
                  path: '/homeIndex/task-student',
                  title: '任务学员',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/mainHome',
        name: 'mainHome',
        component: RouteView,
        redirect: '/mainHome',
        hideChildrenInMenu: true,
        meta: {
          title: '任务',
          unSelectIcon: task,
          selectedIcon: task_a,
          permission: [
            'CLASS_ADMIN',
            'HEAD_TEACHER_LEADER',
            'HEAD_TEACHER_SUPER',
            'TEACHING_STAFF',
            'TEACHING_STAFF_ADMIN',
          ],
        },
        children: [
          {
            path: '/mainHome',
            name: 'mainHome',
            // hidden: true,
            component: () => import('@/views/mainHome/home'),
            meta: {
              title: '任务',
              cnMenuName: '任务',
              pMenuName: 'mainHome',
              icon: task,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
              menu: [
                {
                  path: '/mainHome',
                  title: '任务',
                },
              ],
            },
          },
          {
            path: '/courseSheet',
            name: 'courseSheet',
            hidden: true,
            component: () => import('@/views/schedule/courseSheet'),
            meta: {
              title: '发排课/代课单',
              cnMenuName: '任务',
              pMenuName: 'mainHome',
              icon: task,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/mainHome',
                  title: '任务',
                },
                {
                  path: '/courseSheet',
                  title: '发排课/代课单',
                },
              ],
            },
          },
          {
            path: '/toTaskScheduleClasses',
            name: 'toScheduleClasses',
            hidden: true,
            component: () => import('@/views/student/toScheduleClasses'),
            meta: {
              title: '去排课',
              cnMenuName: '任务',
              pMenuName: 'mainHome',
              icon: task,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/mainHome',
                  title: '任务',
                },

                {
                  path: '/toScheduleClasses',
                  title: '去排课',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/myStudent',
        name: 'myStudent',
        component: RouteView,
        redirect: '/myStudent',
        hideChildrenInMenu: true,
        meta: {
          title: '学生',
          unSelectIcon: newstudent,
          selectedIcon: newstudent_a,
          permission: [
            'CLASS_ADMIN',
            'HEAD_TEACHER_LEADER',
            'X_WUKONG',
            'TEACHING_STAFF',
            'TEACHING_STAFF_ADMIN',
            'HEAD_TEACHER_SUPER',
          ],
        },
        children: [
          {
            path: '/myStudent',
            name: 'myStudent',
            // hidden: true,
            component: () => import('@/views/student/studentIndex'),
            meta: {
              title: '学生',
              keepAlive: true,
              cnMenuName: '任务',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
              ],
            },
          },
          {
            path: '/studentDetail',
            name: 'StudentDetail',
            hidden: true,
            component: () => import('@/views/student/studentDetail'),
            meta: {
              title: '学生详情',
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
              ],
            },
          },
          {
            path: '/app-react/homework-preview',
            name: 'taskStudent',
            component: () => import('@/views/microContainer/'),
            meta: {
              title: '作业预览',
              cnMenuName: '作业预览',
              pMenuName: 'homeworkPreview',
              icon: homepage,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                // {
                //   path: '/homeIndex',
                //   title: '作业预览',
                // },
                // {
                //   path: '/homeIndex/homework-preview',
                //   title: '作业预览',
                // },
              ],
            },
          },
          {
            path: '/studentposter1',
            name: 'poster',
            hidden: true,
            component: () => import('@/views/poster/index'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },

                {
                  path: '/studentposter1',
                  title: '分享海报',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/studentposter',
            name: 'poster',
            hidden: true,
            component: () => import('@/views/poster/index'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/studentposter',
                  title: '分享海报',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/sharingLessons',
            name: 'sharingLessons',
            hidden: true,
            component: () => import('@/views/student/sharingLessons'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/sharingLessons',
                  title: '分享赠课记录',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },

          {
            path: '/wuKongDouDetail',
            name: 'wuKongDouDetail',
            hidden: true,
            component: () => import('@/views/student/wuKongDouDetail'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/wuKongDouDetail',
                  title: '悟空豆明细',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/periodValidity',
            name: 'periodValidity',
            hidden: true,
            component: () => import('@/views/student/periodValidity'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/periodValidity',
                  title: '课时有效期',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/learningPlanningHistory',
            name: 'learningPlanningHistory',
            hidden: true,
            component: () => import('@/views/student/learningPlanningHistory'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/learningPlanningHistory',
                  title: '历史规划',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/learningPlanning',
            name: 'learningPlanning',
            hidden: true,
            component: () => import('@/views/student/learningPlanning'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/learningPlanning',
                  title: '学习规划',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/createOrder',
            name: 'createOrder',
            hidden: true,
            component: () => import('@/views/student/createOrder'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },

                {
                  path: '/createOrder',
                  title: '创建订单',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'HEAD_TEACHER_SUPER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
              ],
            },
          },
          {
            path: '/clueDetail',
            name: 'clueDetail',
            hidden: true,
            component: () => import('@/views/student/clueDetail'),
            meta: {
              title: '线索详情',
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/clueDetail',
                  title: '学生线索',
                },
              ],
            },
          },
          {
            path: '/toScheduleClasses',
            name: 'toScheduleClasses',
            hidden: true,
            component: () => import('@/views/student/toScheduleClasses'),
            meta: {
              title: '去排课',
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/toScheduleClasses',
                  title: '去排课',
                },
              ],
            },
          },
          {
            path: '/orderDetail',
            name: 'OrderDetail',
            hidden: true,
            component: () => import('@/views/student/orderDetail'),
            meta: {
              title: '订单详情',
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/orderDetail',
                  title: '订单详情',
                },
              ],
            },
          },
          {
            path: '/editSendOrder',
            name: 'editSendOrder',
            hidden: true,
            component: () => import('@/views/im/EditSendOrder'),
            meta: {
              menu: [
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/orderDetail',
                  title: '订单详情',
                },
                {
                  path: '/editSendOrder',
                  title: '编辑寄送',
                },
              ],
              cnMenuName: '我的学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: ['CLASS_ADMIN'],
            },
          },

          {
            path: '/courseDurationList',
            name: 'CourseDurationList',
            hidden: true,
            component: () => import('@/views/im/courseDurationList'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/courseDurationList',
                  title: '课时历史',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/editCourseDuration',
            name: 'EditCourseDuration',
            hidden: true,
            component: () => import('@/views/im/editCourseDuration'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/editCourseDuration',
                  title: '课时变更',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/calendar',
            name: 'ClassCalendarAdmin',
            hidden: true,
            component: () => import('@/views/calendar/CalendarIndex'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/calendar',
                  title: '学员课表',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: newstudent,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/lessonReplay',
            name: 'lessonReplay',
            hidden: true,
            component: () => import('@/views/schedule/lessonReplay'),
            meta: {
              menu: [
                {
                  path: '/myStudent',
                  title: '学生',
                },
                {
                  path: '/studentDetail',
                  title: '学生详情',
                },
                {
                  path: '/calendar',
                  title: '学员课表',
                },
                {
                  path: '/lessonReplay',
                  title: '课节回放',
                },
              ],
              cnMenuName: '学生',
              pMenuName: 'myStudent',
              icon: newstudent,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
        ],
      },
      {
        path: '/statisticsIndex',
        name: 'statisticsIndex',
        component: RouteView,
        redirect: '/statisticsIndex',
        hideChildrenInMenu: true,
        meta: {
          title: '统计',
          unSelectIcon: statistics,
          selectedIcon: statistics_a,
          keepAlive: true,
          permission: [
            'CLASS_ADMIN',
            'HEAD_TEACHER_LEADER',
            'TEACHING_STAFF_ADMIN',
            'X_WUKONG',
            'TEACHING_STAFF',
            'HEAD_TEACHER_SUPER',
          ],
        },
        children: [
          {
            path: '/statisticsIndex',
            name: 'statisticsIndex',
            // hidden: true,
            component: () => import('@/views/Statistics/statisticsIndex'),
            meta: {
              title: '统计',
              cnMenuName: '统计',
              pMenuName: 'statisticsIndex',
              icon: statistics,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/statisticsIndex',
                  title: '统计',
                },
              ],
            },
          },
          {
            path: '/referralIndex',
            name: 'referralIndex',
            hidden: true,
            component: () => import('@/views/Statistics/referralIndex'),
            meta: {
              title: '转介绍线索',
              cnMenuName: '统计',
              pMenuName: 'statisticsIndex',
              icon: statistics,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/statisticsIndex',
                  title: '统计',
                },
                {
                  path: '/referralIndex',
                  title: '转介绍线索',
                },
              ],
            },
          },
          {
            path: '/clueDetail1',
            name: 'clueDetail',
            hidden: true,
            component: () => import('@/views/student/clueDetail'),
            meta: {
              title: '线索详情',
              cnMenuName: '统计', // 这个写一级菜单
              pMenuName: 'statisticsIndex',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/statisticsIndex',
                  title: '统计',
                },
                {
                  path: '/referralIndex',
                  title: '转介绍线索',
                },
                {
                  path: '/clueDetail1',
                  title: '线索详情',
                },
              ],
            },
          },

          {
            path: '/studentShareDetail',
            name: 'studentShareDetail',
            hidden: true,
            component: () => import('@/views/Statistics/studentShareDetail'),
            meta: {
              title: '学员分享明细',
              cnMenuName: '统计',
              pMenuName: 'statisticsIndex',
              icon: statistics,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/statisticsIndex',
                  title: '统计',
                },
                {
                  path: '/studentShareDetail',
                  title: '学员分享明细',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/headTeacher',
        name: 'headTeacher',
        component: RouteView,
        redirect: '/headTeacher',
        hideChildrenInMenu: true,
        meta: {
          title: '聊天',
          unSelectIcon: chat,
          selectedIcon: chat_a,
          keepAlive: true,
          permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
        },
        children: [
          {
            path: '/headTeacher',
            name: 'headTeacher',
            // hidden: true,
            component: () => import('@/views/im/headTeacher'),
            meta: {
              title: '聊天',
              cnMenuName: '任务',
              pMenuName: 'headTeacher',
              icon: msg,
              permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
              menu: [
                {
                  path: '/headTeacher',
                  title: '聊天',
                },
              ],
            },
          },
          {
            path: '/poster',
            name: 'poster',
            hidden: true,
            component: () => import('@/views/poster/index'),
            meta: {
              menu: [
                {
                  path: '/headTeacher',
                  title: '聊天',
                },
                {
                  path: '/poster',
                  title: '生成分享海报',
                },
              ],
              cnMenuName: '聊天',
              pMenuName: 'headTeacher',
              icon: msg,
              permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'],
            },
          },
        ],
      },
      {
        path: '/xiaowukong',
        name: 'xiaowukong',
        component: RouteView,
        redirect: '/xiaowukong',
        hideChildrenInMenu: true,
        meta: { title: '客服', keepAlive: true, unSelectIcon: chat, selectedIcon: chat_a, permission: ['X_WUKONG'] },
        children: [
          {
            path: '/xiaowukong',
            name: 'xiaowukong',
            // hidden: true,
            component: () => import('@/views/im/xiaowukong'),
            meta: {
              title: '客服',
              cnMenuName: '客服',
              pMenuName: 'xiaowukong',
              keepAlive: true,

              permission: ['X_WUKONG'],
              menu: [
                {
                  path: '/xiaowukong',
                  title: '客服',
                },
              ],
            },
          },
          {
            path: '/poster',
            name: 'poster',
            hidden: true,
            component: () => import('@/views/poster/index'),
            meta: {
              title: '生成分享海报',
              cnMenuName: '客服',
              pMenuName: 'xiaowukong',
              keepAlive: true,

              permission: ['X_WUKONG'],
              menu: [
                {
                  path: '/xiaowukong',
                  title: '客服',
                },
                {
                  path: '/poster',
                  title: '生成分享海报',
                },
              ],
            },
          },
        ],
      },
      {
        path: '/workoutSchedule',
        component: RouteView,
        name: 'workoutSchedule',
        redirect: '/workoutSchedule/schedule',
        meta: {
          title: '排课',

          unSelectIcon: schedule,
          selectedIcon: schedule_a,
          permission: [
            'CLASS_ADMIN',
            'X_WUKONG',
            'HEAD_TEACHER_LEADER',
            'TEACHING_STAFF_ADMIN',
            'TEACHING_STAFF',
            'HEAD_TEACHER_SUPER',
          ],
        },
        children: [
          {
            path: '/workoutSchedule/schedule',
            name: 'workoutSchedule',
            component: () => import('@/views/schedule/classList'),
            meta: {
              keepAlive: true,
              title: '班级管理',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],

              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
          {
            path: '/workoutSchedule/courseScheduleSheet',
            name: 'courseScheduleSheet',
            hidden: true,
            component: () => import('@/views/schedule/courseScheduleSheet'),
            meta: {
              title: '排课单',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/workoutSchedule/courseSchedule',
                  title: '排课',
                },

                {
                  path: '/courseSchedule',
                  title: '排课单',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
          {
            path: '/workoutSchedule/courseTeacherSheet',
            name: 'courseTeacherSheet',
            hidden: true,
            component: () => import('@/views/schedule/courseTeacherSheet'),
            meta: {
              title: '指定老师发单',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/workoutSchedule/courseSchedule',
                  title: '排课',
                },

                {
                  path: '/courseTeacherSheet',
                  title: '指定老师发单',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },

          {
            path: '/workoutSchedule/classEdit',
            name: 'classEdit',
            hidden: true,
            component: () => import('@/views/schedule/classEdit'),
            meta: {
              title: '班级编辑',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classEdit',
                  title: '班级编辑',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
          {
            path: '/workoutSchedule/classDetail',
            name: 'workoutClassDetail',
            hidden: true,

            component: () => import('@/views/schedule/classDetail'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/lessonReplay',
            name: 'lessonReplay',
            hidden: true,
            component: () => import('@/views/schedule/lessonReplay'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/lessonReplay',
                  title: '课节回放',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/courseSchedule',
            name: 'CourseSchedule',
            hidden: true,
            component: () => import('@/views/schedule/courseSchedule'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/workoutSchedule/courseSchedule',
                  title: '排课',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/teacherClassCalendar',
            name: 'teacherClassCalendar',
            hidden: true,
            component: () => import('@/views/schedule/teacherClassCalendar'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/workoutSchedule/courseSchedule',
                  title: '排课',
                },

                {
                  path: '/workoutSchedule/teacherClassCalendar',
                  title: '老师课表',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/teacherCalendar',
            name: 'teacherClassCalendar',
            hidden: true,
            component: () => import('@/views/schedule/teacherClassCalendar'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },

                {
                  path: '/teacherCalendar',
                  title: '老师课表',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/adjustScheduleList',
            name: 'adjustScheduleList',
            hidden: true,
            component: () => import('@/views/schedule/adjustScheduleList'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/adjustScheduleList',
                  title: '调课列表',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/leaveList',
            name: 'leaveList',
            component: () => import('@/views/schedule/leaveList'),
            meta: {
              title: '请假管理',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF_ADMIN',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],

              menu: [
                {
                  path: '/workoutSchedule/leaveList',
                  title: '请假管理',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
          {
            path: '/workoutSchedule/scheduleDetail',
            name: 'ScheduleDetail',
            hidden: true,
            component: () => import('@/views/schedule/scheduleDetail'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/leaveList',
                  title: '请假管理',
                },
                {
                  path: '/workoutSchedule/scheduleDetail',
                  title: '课节详情',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
                'X_WUKONG',
              ],
            },
          },
          {
            path: '/workoutSchedule/scheduleDetailSub',
            name: 'ScheduleDetail',
            hidden: true,
            component: () => import('@/views/schedule/scheduleDetail'),
            meta: {
              menu: [
                {
                  path: '/workoutSchedule/schedule',
                  title: '班级管理',
                },
                {
                  path: '/workoutSchedule/classDetail',
                  title: '班级详情',
                },
                {
                  path: '/workoutSchedule/scheduleDetailSub',
                  title: '课节详情',
                },
              ],
              cnMenuName: '班级管理',
              pMenuName: 'workoutSchedule',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/workoutSchedule/issuing',
            name: 'issuing',
            component: () => import('@/views/schedule/issuingManagement'),
            meta: {
              title: '发单管理',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF_ADMIN',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],

              menu: [
                {
                  path: '/workoutSchedule/leaveList',
                  title: '发单管理',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
          {
            path: '/workoutSchedule/issuingCourseTeacherSheet',
            name: 'courseTeacherSheet',
            hidden: true,
            component: () => import('@/views/schedule/courseTeacherSheet'),
            meta: {
              title: '指定老师发单',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'X_WUKONG',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/workoutSchedule/issuing',
                  title: '发单管理',
                },

                {
                  path: '/workoutSchedule/issuingCourseTeacherSheet',
                  title: '指定老师发单',
                },
              ],
              cnMenuName: '排课',
              pMenuName: 'workoutSchedule',
            },
          },
        ],
      },

      {
        path: '/administration',
        component: RouteView,
        name: 'administration',
        redirect: '/administration/organization',
        meta: { title: '管理', unSelectIcon: manage, selectedIcon: manage_a },
        children: [
          {
            path: '/administration/organization',
            name: 'organization',
            component: () => import('@/views/im/organization'),
            meta: {
              title: '组织管理',
              permission: ['TEACHING_STAFF', 'TEACHING_STAFF_ADMIN', 'HEAD_TEACHER_SUPER', 'HEAD_TEACHER_LEADER'],
              menu: [
                // {
                //   path: '/administration',
                //   title: '管理',
                // },
                {
                  path: '/administration/organization',
                  title: '组织管理',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },

          {
            path: '/administration/monthlyLimit',
            name: 'monthlyLimit',
            hidden: true,
            component: () => import('@/views/im/monthlyLimit'),
            meta: {
              title: '每月限额',
              permission: ['TEACHING_STAFF', 'TEACHING_STAFF_ADMIN', 'HEAD_TEACHER_SUPER', 'HEAD_TEACHER_LEADER'],
              menu: [
                {
                  path: '/administration/organization',
                  title: '组织管理',
                },
                {
                  path: '/administration/monthlyLimit',
                  title: '每月限额',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },

          {
            path: '/administration/courseDurationApprovalNew',
            name: 'courseDurationApprovalNew',
            component: () => import('@/views/courseDurationApproval/indexNew'),
            meta: {
              title: '审批管理',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/administration/courseDurationApprovalNew',
                  title: '审批管理',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },
          {
            path: '/administration/assignStudents',
            name: 'assignStudents',
            component: () => import('@/views/set/assignStudents'),
            meta: {
              title: '待分配学生',
              permission: ['TEACHING_STAFF', 'TEACHING_STAFF_ADMIN'],
              menu: [
                {
                  path: '/administration/assignStudents',
                  title: '待分配学生',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },
          {
            path: '/administration/lostRefundStudent',
            name: 'lostRefundStudent',
            component: () => import('@/views/lostRefundStudent/list'),
            meta: {
              title: '学员流失',
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                // {
                //   path: '/administration',
                //   title: '管理',
                // },
                {
                  path: '/administration/lostRefundStudent',
                  title: '学员流失',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },

          {
            path: '/lostRefundEdit',
            name: 'lostRefundEdit',
            hidden: true,
            component: () => import('@/views/lostRefundStudent/edit'),
            meta: {
              menu: [
                {
                  path: '/administration/lostRefundStudent',
                  title: '学员流失',
                },
                {
                  path: '/lostRefundEdit',
                  title: '编辑',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/lostRefundDetail',
            name: 'lostRefundDetail',
            hidden: true,
            component: () => import('@/views/lostRefundStudent/detail'),
            meta: {
              menu: [
                {
                  path: '/administration/lostRefundStudent',
                  title: '学员流失',
                },
                {
                  path: '/lostRefundDetail',
                  title: '学员流失详情',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
              icon: student,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
          {
            path: '/administration/dataUpload',
            name: 'DataUpload',
            component: () => import('@/views/student/dataUpload'),
            meta: {
              title: '数据上传',
              permission: [
                // 教务主管可见
                'TEACHING_STAFF_ADMIN',
              ],
              menu: [
                {
                  path: '/administration/dataUpload',
                  title: '数据上传',
                },
              ],
              cnMenuName: '管理',
              pMenuName: 'administration',
            },
          },
        ],
      },
      {
        path: '/orderIndex',
        name: 'orderIndex',
        component: RouteView,
        redirect: '/orderIndex',
        hideChildrenInMenu: true,
        meta: {
          title: '订单',
          unSelectIcon: order,
          selectedIcon: order_a,
          keepAlive: true,
          permission: [
            'CLASS_ADMIN',
            'HEAD_TEACHER_LEADER',
            'TEACHING_STAFF_ADMIN',
            'TEACHING_STAFF',
            'HEAD_TEACHER_SUPER',
          ],
        },
        children: [
          {
            path: '/orderIndex',
            name: 'orderIndex',
            // hidden: true,
            component: () => import('@/views/mainHome/orderIndex'),
            meta: {
              title: '订单',
              cnMenuName: '订单',
              pMenuName: 'orderIndex',
              icon: statistics,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/orderIndex',
                  title: '订单',
                },
              ],
            },
          },
          {
            path: '/orderIndex/orderDetail',
            name: 'OrderDetail',
            hidden: true,
            component: () => import('@/views/student/orderDetail'),
            meta: {
              title: '订单详情',
              cnMenuName: '订单',
              pMenuName: 'orderIndex',

              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'TEACHING_STAFF_ADMIN',
                'TEACHING_STAFF',
                'HEAD_TEACHER_SUPER',
              ],
              menu: [
                {
                  path: '/orderIndex',
                  title: '订单',
                },
                {
                  path: '/orderDetail',
                  title: '订单详情',
                },
              ],
            },
          },
        ],
      },
      {
        path: '/ruleSet',
        component: RouteView,
        name: 'set',
        redirect: '/ruleSet/allocationRules',
        meta: { title: '设置', unSelectIcon: setting, selectedIcon: setting_a, permission: ['TEACHING_STAFF_ADMIN'] },
        children: [
          {
            path: '/ruleSet/allocationRules',
            name: 'allocationRules',
            component: () => import('@/views/set/allocationRules'),
            meta: {
              title: '分配规则',
              permission: ['TEACHING_STAFF_ADMIN'],
              menu: [
                {
                  path: '/administration/organization',
                  title: '分配规则',
                },
              ],
              cnMenuName: '设置',
              pMenuName: 'set',
            },
          },
          {
            path: '/ruleSet/price-sheet-template-list',
            name: 'price-sheet-template-list',
            component: () => import('@/views/set/priceSheetTemplateList'),
            meta: {
              title: '报价单配置',
              permission: ['TEACHING_STAFF_ADMIN'],
              cnMenuName: '设置',
              pMenuName: 'set',
            },
          }
        ],
      },
  
      {
        path: '/setHome',
        name: 'setHome',
        component: RouteView,
        redirect: '/set',
        hideChildrenInMenu: true,
        meta: {
          title: '设置',
          permission: [
            'CLASS_ADMIN',
            'HEAD_TEACHER_LEADER',
            'X_WUKONG',
            'TEACHING_STAFF',
            'TEACHING_STAFF_ADMIN',
            'HEAD_TEACHER_SUPER',
          ],
        },
        children: [
          {
            path: '/set',
            name: 'set',
            component: () => import(/* webpackChunkName: "set" */ '@/views/user/Set'),
            meta: {
              title: '设置',
              cnMenuName: '设置',
              pMenuName: 'setHome',
              menu: [
                {
                  path: '/set',
                  title: '设置',
                },
              ],
              // icon: set,
              permission: [
                'CLASS_ADMIN',
                'HEAD_TEACHER_LEADER',
                'X_WUKONG',
                'TEACHING_STAFF',
                'TEACHING_STAFF_ADMIN',
                'HEAD_TEACHER_SUPER',
              ],
            },
          },
        ],
      },
    ],
  },

  {
    path: '/playback',
    name: 'playback',
    component: () => import(/* webpackChunkName: "playback" */ '@/views/mainHome/Playback'),
    meta: { title: '回放', permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'] },
  },
  {
    path: '/lessonReplayPlayback',
    name: 'lessonReplayPlayback',
    hidden: true,
    component: () => import('@/views/schedule/lessonReplay'),
    meta: { title: '回放', permission: ['CLASS_ADMIN', 'HEAD_TEACHER_LEADER'] },
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/user/Login'),
      },
      {
        path: 'binding',
        name: 'binding',
        component: () => import(/* webpackChunkName: "binding" */ '@/views/user/Binding'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '@/views/user/Register'),
      },
    ],
  },
  {
    path: '/material-preview',
    name: 'material-preview',
    component: () => import(/* webpackChunkName: "material-preview" */ '@/views/materialPreview'),
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/exception/404'),
  },
];
