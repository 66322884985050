<template>
  <div>
    <div class="task-nav">
      <div class="table-page-search-wrapper">
        <a-form layout="inline" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="学员">
                <a-input
                  placeholder="姓名或学号"
                  v-model="studentCondition"
                  allow-clear
                  @pressEnter="initSearchData('search')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="提交人">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="startUserId"
                >
                  <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.uuid">{{
                    item.fullName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="订单号">
                <a-input
                  placeholder="请输入"
                  v-model="orderNumber"
                  allow-clear
                  @pressEnter="initSearchData('search')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="订单类型">
                <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="orderType">
                  <a-select-option :value="item.value" v-for="(item, index) in orderTypeArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="订单审批类型">
                <a-select
                  placeholder="请选择"
                  mode="multiple"
                  allowClear
                  @change="initSearchData('search')"
                  v-model="orderReviewTypes"
                >
                  <a-select-option :value="item.value" v-for="(item, index) in transactionCategoryArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="审核状态">
                <a-select
                  placeholder="请选择"
                  allowClear
                  @change="initSearchData('search')"
                  v-model="orderReviewStatus"
                >
                  <a-select-option :value="item.value" v-for="(item, index) in approveStatusArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="float: right; text-align: right">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
                <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="table-wrap">
      <div class="sel-navwrap">
        <div class="sel-left">
          <a-select
            v-model="selType"
            class="sel-task"
            :default-value="selTabData[0].value"
            style="width: 110px; font-weight: bold"
            @change="initSearchData('search')"
          >
            <a-select-option v-for="item in selTabData" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :loading="showLoading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="studentName" slot-scope="studentName, record">
          <span>{{ record.processVariables.student.name }} ({{ record.processVariables.student.code }})</span>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="finish" slot-scope="finish, record">
          <span>{{ initOptionsType(record.businessTaskStatus, approveStatusArr) }}</span>
        </template>

        <template slot="approveStatus" slot-scope="approveStatus">
          <span :style="{ color: approveStatus === 'REJECTED' ? 'red' : '' }">{{
            initOptionsType(approveStatus, approveStatusArr)
          }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">查看详情</span>
        </template>
      </a-table>
    </div>

    <a-drawer
      class="test-main"
      title="审批详情"
      :body-style="{ paddingBottom: '80px' }"
      placement="right"
      width="700"
      :visible="visible"
      @close="onClose"
    >
      <div class="task-info" v-if="formInfoData">
        <div class="task-form">
          <p class="form-name" style="margin-bottom: 12px">
            {{ formInfoData.processVariables.question }}
            <span class="pending-status" v-if="formInfoData.businessTaskStatus === 'PENDING'">待审核</span>
            <span class="success-status" v-if="formInfoData.businessTaskStatus === 'PASSED'">已通过</span>
            <span class="refuse-status" v-if="formInfoData.businessTaskStatus === 'REJECTED'">已拒绝</span>
            <span class="cancel-status" v-if="formInfoData.businessTaskStatus === 'ORDER_CANCEL'">订单已取消</span>
          </p>
          <div class="form-teacher">
            <img :src="defaultImg" alt="" />
            <span>提交人：{{ formInfoData.processVariables.startUserName }}</span>
            |
            <span>提交时间：{{ formInfoData.processVariables.createTime }}</span>
          </div>
        </div>
        <div class="line-wrap">
          <InfoTitle title="关联学生" />
          <div class="student-wrap">
            <div class="student-item">
              <img :src="defaultImg" alt="" class="handler-img" />
              <div>
                <p class="student-name cursor" @click="hrefStudent">
                  {{ formInfoData.processVariables.student.name }} ({{ formInfoData.processVariables.student.code }})>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="apply-detail">
          <InfoTitle title="审批详情" />
          <div class="apply-detailContent">
            <div class="apply-item">
              <span class="apply-label">科目：</span
              ><span class="apply-content">{{ formInfoData.processVariables.subjectName }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">订单审批类型：</span
              ><span class="apply-content">{{ formInfoData.processVariables.question }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">订单类型：</span
              ><span class="apply-content">{{
                formInfoData.processVariables.orderType &&
                formInfoData.processVariables.orderType.length > 0 &&
                formInfoData.processVariables.orderType.join(',')
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">订单编号：</span
              ><span class="apply-content" style="color: #3372fe; cursor: pointer" @click="hrefOrder">{{
                formInfoData.processVariables.orderNumber
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">订单备注：</span
              ><span class="apply-content">{{ formInfoData.processVariables.orderInfo.instruction }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">订单商品：</span
              ><span class="apply-content">
                <a-table
                  size="small"
                  :pagination="false"
                  :columns="smallColumns"
                  :data-source="formInfoData.processVariables.orderInfo.lines"
                  :rowKey="
                    (record, index) => {
                      return index;
                    }
                  "
                >
                  <template slot="name" slot-scope="name, record">
                    <a-popover title="商品详情">
                      <template slot="content">
                        <div>
                          <p v-for="(item, index) in record.items" :key="index">{{ item.name }}x{{ item.quantity }}</p>
                        </div>
                      </template>
                      <span>{{ name }} <a-icon type="ordered-list" style="margin-left: 3px" /></span>
                    </a-popover>
                  </template>
                  <template slot="discount" slot-scope="discount, record">
                    <span v-if="record.oldDiscount !== null && record.discount !== record.oldDiscount"
                      ><span style="color: red">{{ record.discount }}% </span>(配置折扣{{ record.oldDiscount }}%)</span
                    >
                    <span v-else>{{ record.discount || '-' }} <span v-if="record.discount">%</span></span>
                  </template>
                  <template slot="quantity" slot-scope="quantity, record">
                    <span v-if="record.oldQuantity !== null && record.quantity !== record.oldQuantity"
                      ><span style="color: red">{{ record.quantity }}</span
                      >(配置数量{{ record.oldQuantity }})</span
                    >
                    <span v-else>{{ record.quantity || '-' }}</span>
                  </template>
                </a-table>
              </span>
            </div>
          </div>
        </div>

        <div class="apply-detail">
          <InfoTitle title="审批结果" />
          <div class="apply-detailContent" v-if="assigneesList.length > 0">
            <template>
              <MyProcessingRecords :class="subDetailType" :assigneesDatas="assigneesList" />
            </template>
          </div>
        </div>
        <div class="taskFormBtn" v-if="selType === 'PENDING'">
          <a-button type="primary" :style="{ marginRight: '8px' }" @click="examineFun('PASSED')"> 审核通过 </a-button>
          <a-button :style="{ marginRight: '8px' }" @click="examineFun('REJECTED')"> 不通过 </a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer title="操作" placement="right" width="700" :visible="operatevisible" @close="clearnCurrentView">
      <TestOperate
        v-if="operatevisible"
        :classType="formInfoData.classType"
        :taskType="formInfoData.type"
        :taskTypeName="formInfoData.processVariables.question"
        :taskStatus="taskStatus"
        @onSubmit="onGetformData"
        @clearnCurrentView="clearnCurrentView"
        :source="subDetailType"
      >
      </TestOperate>
    </a-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import { MyProcessingRecords, TestOperate } from '@wk/tch-sc';
import defaultImg from '@/assets/service_pic_head.png';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import {
  getHeadmasterList,
  queryStudentOrderStatus,
  submitStatusApproval,
  queryCourseSchedule,
  feedBackDetail,
  initOptions,
  rejectTaskDetail,
  completeTaskDetail,
} from '@/api/headTeacher';
import { getDdlLabel } from '@/utils/util';

export default {
  name: 'classApproval',
  components: { InfoTitle, MyProcessingRecords, TestOperate },
  data() {
    return {
      taskId: '',
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      showLoading: false,
      taskStatus: 'complate',
      options: {},
      assigneesList: [],
      orderType: '',
      operatevisible: false,
      subDetailType: 'classAdmin',
      smallColumns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },

        {
          title: '商品组合',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '折扣',
          dataIndex: 'discount',
          key: 'discount',
          scopedSlots: { customRender: 'discount' },
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          scopedSlots: { customRender: 'quantity' },
        },
      ],
      smallTableData: [],
      studentCondition: '',
      startUserId: undefined,
      orderNumber: '',
      orderTag: undefined,
      orderReviewTypes: [],
      orderReviewStatus: undefined,
      approveStatus: undefined,
      orderTypeArr: [
        { label: '学生续费', value: '续费' },
        { label: '学生补升', value: '补升' },
        // { label: '定金', value: '定金' },
        // { label: '新生报班', value: '新生报班' },
        // { label: '国际运费', value: '国际运费' },
        // { label: '学员升级', value: '学员升级' },
        // { label: '活动寄送', value: '活动寄送' },
      ],
      searchName: '',
      userInfo: {},
      courseScheduleObj: {},
      tableSorter: {},
      remarkTC: '',
      examineTitle: '',
      visibleResult: false,
      actualData: {},
      visibleExamine: false,
      defaultImg,
      formInfoData: {
        processVariables: {
          student: {},
          orderType: [],
          orderInfo: {},
          orderType: [],
        },
      },
      selType: 'PENDING',
      headmasterList: [],
      submitterId: undefined,
      transactionCategory: undefined,
      transactionCategoryArr: [
        { label: '超额赠课', value: 'GIFT_ABOVE' },
        { label: '订单折扣', value: 'MAKE_UP_DISCOUNT' },
        { label: '超额赠额度', value: 'SHARE_ABOVE' },
      ],

      approveStatusArr: [
        { label: '待审批', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
        { label: '订单已取消', value: 'ORDER_CANCEL' },
      ],
      visible: false,
      current: 1,
      selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
        { label: '订单已取消', value: 'ORDER_CANCEL' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '学员',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/studentDetail',
                    query: {
                      uuid: record.processVariables.student.studentId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '科目',
          dataIndex: 'processVariables.subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '订单类型',
          dataIndex: 'processVariables.orderType',
          key: 'orderType',
        },
        {
          title: '订单审批类型',
          dataIndex: 'processVariables.question',
          key: 'question',
        },
        {
          title: '订单编号',
          dataIndex: 'processVariables.orderNumber',
          key: 'orderNumber',
        },
        {
          title: '审核状态',
          dataIndex: 'finish',
          key: 'finish',
          scopedSlots: { customRender: 'finish' },
        },
        {
          title: '提交人',
          dataIndex: 'processVariables.startUserName',
          key: 'submitterName',
        },

        {
          title: '提交日期',
          dataIndex: 'processVariables.createTime',
          key: 'startTime',
          sorter: true,
        },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    moment,
    // 跳转订单
    hrefOrder() {
      const routeUrl = this.$router.resolve({
        path: '/orderDetail',
        query: {
          orderId: this.formInfoData.processVariables.orderId,
          studentId: this.formInfoData.processVariables.student.studentId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    onGetformData(data) {
      let params = {};
      switch (data.taskType) {
        case 'complate':
          params = {
            message: data.formData.message,
            userId: this.userInfo.userID,
            taskId: this.formInfoData.id,
            processInstanceId: this.formInfoData.processInstanceId,
          };
          completeTaskDetail(params, this.formInfoData.id, this.formInfoData.processInstanceId).then((res) => {
            this.onCloseCourse();
          });
          break;
        case 'refuse':
          params = {
            taskId: this.formInfoData.id,
            userId: this.userInfo.userID,
            reason: data.formData.reason,
            processInstanceId: this.formInfoData.processInstanceId,
          };
          rejectTaskDetail(params).then((res) => {
            this.onCloseCourse();
          });
          break;
      }
    },
    onCloseCourse() {
      this.operatevisible = false;
      this.visible = false;
      this.initSearchData('search');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },
    // 枚举类型
    initOptions() {
      initOptions().then((res) => {
        this.options = res.data.content;
      });
    },
    initRole(datas) {
      return datas.map((item) => {
        let accounts = null;
        let taskFromAccount = null;
        if (item.accounts) {
          accounts = item.accounts.map((i) => {
            const rolesC = [];
            if (i && i.roles) {
              i.roles.forEach((k) => {
                rolesC.push(this.initOptionsType(k, this.options.userRole));
              });
            }
            return {
              ...i,
              rolesC: rolesC.join('/'),
            };
          });
        }
        if (item.taskFromAccount) {
          taskFromAccount = item.taskFromAccount;
          const taskFromAccountRoles = [];
          if (taskFromAccount.roles) {
            taskFromAccount.roles.forEach((k) => {
              taskFromAccountRoles.push(this.initOptionsType(k, this.options.userRole));
            });
          }

          taskFromAccount.rolesC = taskFromAccountRoles.join('/');
        }
        return {
          ...item,
          taskFromAccount,
          accounts,
        };
      });
    },
    // 获取抽屉里反馈详情
    feedBackDetail(datas) {
      const params = {
        processInstanceId: datas.processInstanceId,
        taskId: datas.id,
      };
      feedBackDetail(params).then((res) => {
        this.assigneesList = this.initRole(res.data.content.taskLogs);
      });
    },
    clearnCurrentView() {
      this.operatevisible = false;
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    playback() {
      window.open(this.courseScheduleObj.liveUrl, '_blank');
    },

    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj = res.data.content;
      });
    },
    cancelResult() {
      this.visibleResult = false;
      this.cancelExamine();
      this.visible = false;
      this.initSearchData();
    },
    examineFun(val) {
      this.approveStatusTC = val;
      this.operatevisible = true;
      if (val === 'PASSED') {
        this.examineTitle = '审核通过';
        this.taskStatus = 'complate';
      } else {
        this.examineTitle = '审核不通过';
        this.taskStatus = 'refuse';
      }
    },
    cancelExamine() {
      this.visibleExamine = false;
      this.remarkTC = '';
    },
    saveExamine() {
      const params = {
        reviewId: this.formInfoData.uuid,
        approveStatus: this.approveStatusTC,
        remark: this.remarkTC,
      };
      submitStatusApproval(params).then((res) => {
        this.$message.success('提交成功');
        this.cancelExamine();
        this.visible = false;
        this.initSearchData();
      });
    },
    hrefStudent() {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: this.formInfoData.processVariables.student.id },
      });
      window.open(routeUrl.href, '_blank');
    },

    onClose() {
      this.visible = false;
    },
    lookDetail(record) {
      this.feedBackDetail(record);
      this.formInfoData = record;

      this.visible = true;
    },
    onSearchReport() {},

    searchHeadmaster(name) {
      const params = {
        roles: [
          'ADMIN',
          'SUPER_ADMIN',
          'X_WUKONG',
          'HEAD_TEACHER_LEADER',
          'HEAD_TEACHER_SUPER',
          'CLASS_ADMIN',
          'TEACHING_STAFF',
          'TEACHING_STAFF_ADMIN',
        ],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterList = res.data.content;
        console.log(this.headmasterList);
      });
    },
    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;
      this.initPagination.current = data.current;
      this.current = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.initSearchData();
    },

    resetCondition() {
      this.studentCondition = '';
      this.startUserId = undefined;
      this.orderNumber = '';
      this.orderType = undefined;
      this.orderReviewTypes = [];
      this.orderReviewStatus = undefined;
      this.current = 1;
      this.pageSize = 10;
      this.initSearchData('search');
    },
    initSearchData(act) {
      if (act === 'search') {
        this.current = 1;
      }
      this.showLoading = true;
      const params = {
        type: this.selType,
        studentCondition: this.studentCondition,
        startUserId: this.startUserId,
        orderNumber: this.orderNumber,
        orderReviewTypes: this.orderReviewTypes,
        orderType: this.orderType,
        approveStatus: this.orderReviewStatus,
        taskId: this.taskId,
        categorySet: ['【中文】花果订单审批', '【数学】花果订单审批', '【英文】花果订单审批'],
        currentPage: this.current,
        pageSize: this.pageSize,
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = this.tableSorter.columnKey;
        params.sort = sort;
      }

      queryStudentOrderStatus(params)
        .then((res) => {
          this.tableData = res.data.content;
          this.current = res.data.currentPage;
          this.pageSize = res.data.pageSize;
          this.initPagination.total = res.data.totalCount;
          this.initPagination.current = res.data.currentPage;
          this.initPagination.pageSize = res.data.pageSize;

          this.showLoading = false;
        })
        .catch((error) => {
          this.showLoading = false;
        });
    },
  },
  created() {
    this.userInfo = storage.get('userInfo');
    // 如果是飞书通知过来的，筛选出待审批的数据
    this.taskId = this.$route.query.taskId || '';
    if (this.taskId) {
      this.selType = 'PENDING';
    } else if (this.userInfo.roleName === 'CLASS_ADMIN') {
      this.selType = 'OWN';
    }
    this.initOptions();
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.operation {
  color: #3372fe;
  cursor: pointer;
}

/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 120px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 80%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .cancel-status {
      color: #454444;
      background-color: rgba(118, 114, 114, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
  flex: 1;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
</style>
