<template>
  <div>
    <div class="header-top" v-if="submitFlag" style="background-color: #fdeeee">
      <div class="order-title">订单确认页</div>
      <div class="submit-wrap">
        <div class="submit-txt">请再次确认订单客户、商品信息后，进行下单</div>
        <a-steps :current="1" size="small">
          <a-step title="选择订单商品" />
          <a-step title="核对订单信息" />
          <a-step title="确认无误，立即下单" />
        </a-steps>
      </div>
    </div>
    <div class="header-top">
      <div class="order-title">学员订单</div>
      <div>
        <span class="order-label">订单标签：</span>·
        <span v-if="!submitFlag">
          <a-checkable-tag
            v-for="(item, index) in orderTabsList"
            :checked="orderTabsList[index].checked"
            :key="index"
            @change="handleChangeTags(item, index)"
          >
            {{ item.label }}
          </a-checkable-tag>
        </span>
        <span v-else>
          <a-checkable-tag
            v-for="(item, index) in selTags"
            :checked="item.checked"
            :key="index"
          >
            {{ item.label }}
          </a-checkable-tag>
        </span>

        <span class="student-info"
          >学员信息：
          <span>{{ studentProfile.fullName }}({{ studentProfile.code }})</span>
          <span class="info-txt">{{ studentProfile.mobile }}</span>
          <span class="info-txt">{{ studentProfile.email }}</span>
          <span class="info-txt">{{ studentProfile.country }}</span>
        </span>
      </div>
    </div>
    <div class="order-content">
      <div class="order-title">订单商品</div>
      <div style="margin-bottom: 20px" v-if="!submitFlag">
        <a-button type="primary" @click="addProduct">
          <!-- <a-icon type="plus" /> -->
          添加商品
        </a-button>
        <span style="padding: 0 5px 0 15px; font-weight: bold">货币:</span>
        <a-select placeholder="请选择" style="width: 100px" v-model="currencyType">
          <a-select-option
            :value="item.value"
            :disabled="item.checked"
            v-for="item in currencyList"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <a-table :pagination="false" :data-source="checkListGoods" :columns="goodsColumns">
        <template slot="quantity" slot-scope="quantity, record, index">
          <editable-cell
            :text="quantity"
            :originText="record.quantitySub"
            :parentName="record.parentName"
            type="quantity"
            :submitFlag="submitFlag"
            @change="onCellChange(index, 'quantity', $event)"
          />
        </template>
        <template slot="name" slot-scope="name, record">
          <a-popover title="商品详情">
            <template slot="content">
              <div>
                <p v-for="(item, index) in record.items" :key="index">
                  {{ item.item.name }}x{{ item.quantity }}
                </p>
              </div>
            </template>
            <span
              >{{ record.parentName ? `${record.parentName}(${name})` : name }}
              <a-icon type="ordered-list" style="margin-left: 3px"
            /></span>
          </a-popover>
        </template>
        <template slot="price" slot-scope="price, record">
          <span v-if="record.hasCurrency">{{ record.price }}</span>
          <span v-else style="color: red">无效价格</span>
        </template>
        <template slot="discounts" slot-scope="discounts, record, index">
          <editable-cell
            :text="discounts"
            :submitFlag="submitFlag"
            type="discounts"
            @change="onCellChange(index, 'discounts', $event)"
          />
        </template>
        <template slot="tag" slot-scope="tag, record">
          <a-tag
            color="blue"
            v-for="(item, index) in onTagsData(record.tags)"
            :key="index"
            >{{ item }}</a-tag
          >
        </template>
        <template slot="tax" slot-scope="tax, record, index">
          {{ handleTax(record, index) }}
        </template>
        <template slot="subTotal" slot-scope="subTotal, record, index">
          {{ handleSubtotal(record, index) }}
        </template>
        <template slot="operation" slot-scope="text, record, index" v-if="!submitFlag">
          <a-icon type="delete" @click="deleteItem(index)" />
        </template>
      </a-table>
      <div class="other-wrap">
        <div class="left-wrap">
          <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
            <a-form-item label="订单备注">
              <div
                v-if="submitFlag"
                style="padding-left: 20px; max-width: 500px; word-wrap: break-word"
              >
                {{ orderRemark }}
              </div>
              <a-input
                v-else
                v-model="orderRemark"
                allowClear
                placeholder="请输入"
                :maxLength="500"
                style="width: 300px"
              />
              <span style="margin-left: 20px; color: red" v-if="!submitFlag"
                >注意：该订单备注对学员家长可见，请谨慎填写！</span
              >
            </a-form-item>
            <a-form-item label="订单有效期">
              <div v-if="submitFlag" style="padding-left: 20px">
                {{ initOptionsType(expireTime, expireTimes) }}
              </div>

              <a-select
                v-else
                style="width: 300px"
                placeholder="请选择"
                allowClear
                v-model="expireTime"
              >
                <a-select-option
                  v-for="(item, index) in expireTimes"
                  :key="index"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form>
        </div>
        <div class="right-wrap">
          <div class="right-item">
            <span class="right-label">税:</span
            ><span class="right-con">{{ handleTaxAmount() }}</span>
          </div>
          <div class="right-item">
            <span class="right-label">运费:</span>
            <span class="right-con">
              <span v-if="submitFlag">{{ freight }}</span>

              <a-input
                v-else
                v-model="freight"
                allowClear
                placeholder="请输入"
                style="width: 100px"
            /></span>
          </div>
          <div class="right-item">
            <span class="right-label">代金券:</span>
            <span class="right-con">
              <span v-if="submitFlag">{{ checkedCouponNominalValue }}</span>
              <a-select
                v-else
                style="width: 100px"
                v-model="couponId"
                allowClear
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in couponOptions"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </span>
          </div>
          <div class="right-item">
            <span class="right-label">总价:</span
            ><span class="right-con"
              >{{ handelTotal() }}
              <span v-if="checkedCouponNominalValue > 0"
                >（共减{{ checkedCouponNominalValue }}）</span
              >
            </span>
          </div>
          <div class="right-item">
            说明：该订单支付后该学员课包有效期将延长<span style="color: #04cb94"
              >{{ delayMonth }}个月</span
            >
          </div>
        </div>
      </div>
      <div style="margin-top: 12px; text-align: right">
        <a-button type="primary" v-if="!submitFlag" @click="handleConfirmSubmit"
          >提交订单</a-button
        >
        <a v-if="submitFlag" style="margin-right: 20px" @click="backBefore">返回上一页</a>
        <a-button
          type="primary"
          v-if="submitFlag"
          :loading="iconLoading"
          @click="handleSubmit"
          >确认无误，立即下单</a-button
        >
      </div>
    </div>
    <!-- 选择商品弹窗 -->
    <a-modal
      :visible="isVisible"
      width="1000px"
      title="选择商品"
      @ok="handleGoodsOk"
      :loading="isConfirmLoading"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <div style="height: 600px">
        <div style="margin-bottom: 12px">
          <a-form layout="inline" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
            <a-form-item label="商品标签">
              <a-select
                style="width: 200px"
                mode="multiple"
                placeholder="请选择"
                allowClear
                v-model="formData.tags"
              >
                <a-select-option
                  v-for="(item, index) in goodsTabsList"
                  :key="index"
                  :disabled="item.checked"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item label="商品名称">
              <a-input
                v-model="formData.name"
                allowClear
                placeholder="请输入"
                @pressEnter="onGetInventoryList('search')"
              />
            </a-form-item>

            <a-form-item>
              <a-button @click="onGetInventoryList('search')" type="primary"
                >搜索</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <div
          style="
            height: calc(600px - 90px);
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="flex: 1">
            <a-table
              :scroll="{ y: 450 }"
              :loading="inventoryPage.busy"
              :columns="productColumns"
              :data-source="inventoryPage.content"
              @change="handleTableChange"
              :pagination="initPagination"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template slot="name" slot-scope="name, record">
                <a-popover title="商品详情">
                  <template slot="content">
                    <div>
                      <p v-for="(item, index) in record.items" :key="index">
                        {{ item.item.name }}x{{ item.quantity }}
                      </p>
                    </div>
                  </template>
                  <span
                    >{{ name }} <a-icon type="ordered-list" style="margin-left: 3px"
                  /></span>
                </a-popover>
              </template>
              <template slot="price" slot-scope="price, record">
                <span v-for="(item, index) in record.prices" :key="index"
                  >{{ item.currency }}: {{ item.value }}
                  {{ index < record.prices.length - 1 ? " | " : "" }}</span
                >
              </template>
              <template slot="tag" slot-scope="tag, record">
                <a-tag
                  color="blue"
                  v-for="(item, index) in onTagsData(record.tags)"
                  :key="index"
                  >{{ item }}</a-tag
                >
              </template>
              <template slot="operation" slot-scope="operation, record, index">
                <a-checkbox
                  @change="onItemChecked($event, index)"
                  :checked="record.checked"
                  :disabled="record.disabled"
                >
                </a-checkbox>
              </template>
            </a-table>
          </div>

          <div class="check-list">
            <div
              style="margin-bottom: 6px"
              v-for="(item, index) in listOfData"
              :key="index"
            >
              {{ item.name }}&nbsp;
              <a-icon type="close" @click="handelDeleteItem(item)" />
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import storage from "store";
import sessionStorage from "store/storages/sessionStorage";
import { postReq, getReq } from "@/api/schedule";
import { getCoupons } from "@/api/order.js";

const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="!submitFlag" class="editable-cell-input-wrapper" >
          <a-input :value="value" @change="handleChange" @pressEnter="check" style="width:80px" @blur="check" />
          <span  v-if="type==='quantity' && parentName">（单课包默认配置{{originText}}）</span>
          <span v-if="type==='discounts'" style="padding-left:8px;"> % </span>

        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
           <span  v-if="type==='quantity' && parentName">（单课包默认配置{{originText}}）</span>
            <span v-if="type==='discounts'"> % </span>
        </div>
      </div>
    `,
  props: ["text", "type", "submitFlag", "originText", "parentName"],
  // parentName判断是否展示单课包默认配置
  data() {
    return {
      value: this.text,
      editable: true,
    };
  },
  methods: {
    handleChange(e) {
      const { value } = e.target;

      this.value = value;
    },
    check() {
      if (this.type === "discounts") {
        if (this.value > 100) {
          this.$message.warning("折扣金额有误，请重新输入");
          return;
        }
        this.value = parseFloat(this.value).toFixed(2);
      } else if (this.type === "quantity") {
        if (!/(^[1-9]\d*$)/.test(this.value)) {
          this.$message.warning("数量请输入正整数");
          return;
        }
      }
      // this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      // this.editable = true;
    },
  },
};
const session = storage.createStore(sessionStorage);

export default {
  name: "createOrder",
  watch: {
    currencyType(val) {
      if (!val) return;
      this.handleGetCoupons();
    },
  },
  computed: {
    checkedCouponNominalValue() {
      
      return (
        this.couponOptions.find((item) => item.value === this.couponId)
          ?.deductibleAmount ?? 0
      );
    },
  },
  components: {
    EditableCell,
  },
  data() {
    return {
      subject: "",
      delayMonth: 0,
      iconLoading: false,
      selTags: [],
      couponId: undefined,
      couponOptions: [],
      submitFlag: false, // 控制页面是编辑，还是展示
      userInfo: storage.get("userInfo"),
      currencyType: "",
      freight: 0,
      // 订单标签
      orderTabsList: [
        { label: "续费", value: "续费", text: "Renew", checked: false },
        { label: "补升", value: "补升", text: "Upgrade", checked: false },
        { label: "定金", value: "定金", text: "StudentUpgrade", checked: false },
        { label: "学员升级", value: "学员升级", text: "StudentUpgrade", checked: false },
        {
          label: "竞赛代报名",
          value: "竞赛代报名",
          text: "StudentUpgrade",
          checked: false,
        },
      ],
      expireTime: 1440,
      expireTimes: [
        { label: "30分钟", value: 30 },
        { label: "1小时", value: 60 },
        { label: "2小时", value: 120 },
        { label: "3小时", value: 180 },
        { label: "6小时", value: 360 },
        { label: "12小时", value: 720 },
        { label: "24小时", value: 1440 },
        { label: "2天", value: 2880 },
        { label: "3天", value: 4320 },
        { label: "4天", value: 5760 },
        { label: "5天", value: 7200 },
        { label: "6天", value: 8640 },
        { label: "7天", value: 10080 },
      ],
      orderRemark: "",

      goodsColumns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          fixed: "left",
          dataIndex: "num",
          customRender: (text, record, index) => index + 1,
        },
        {
          title: "商品组合",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },

        {
          title: "商品标签",
          dataIndex: "tag",
          key: "tag",
          scopedSlots: { customRender: "tag" },
        },

        {
          title: "数量",
          dataIndex: "quantity",
          width: 280,
          key: "quantity",
          scopedSlots: { customRender: "quantity" },
        },
        {
          title: "价格",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
        },

        {
          title: "优惠",
          dataIndex: "discounts",
          key: "discounts",
          width: 120,
          scopedSlots: { customRender: "discounts" },
        },
        {
          title: "税",
          dataIndex: "tax",
          key: "tax",
          scopedSlots: { customRender: "tax" },
        },
        {
          title: "小计",
          dataIndex: "subTotal",
          key: "subTotal",
          scopedSlots: { customRender: "subTotal" },
        },

        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          with: 120,
          fixed: "right",
          scopedSlots: { customRender: "operation" },
        },
      ],
      checkListGoods: [], // 已选择商品
      listOfData: [], // 临时存储已选择商品
      inventoryPage: {
        content: [],
        busy: false,
      },
      showLoading: false,
      currencyList: [
        // { label: 'CNY', value: 'CNY', checked: false },
        { label: "NZD", value: "NZD", checked: false },
        { label: "USD", value: "USD", checked: false },
        { label: "AUD", value: "AUD", checked: false },
        { label: "HKD", value: "HKD", checked: false },
        { label: "EUR", value: "EUR", checked: false },
        { label: "CHF", value: "CHF", checked: false },
        { label: "GBP", value: "GBP", checked: false },
        { label: "JPY", value: "JPY", checked: false },
        { label: "SGD", value: "SGD", checked: false },
        { label: "CAD", value: "CAD", checked: false },
      ],
      productData: [],
      pageNum: 1,
      current: 1,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      productColumns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          fixed: "left",
          dataIndex: "num",
          customRender: (text, record, index) =>
            `${(this.initPagination.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: "商品名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },

        {
          title: "价格",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
        },

        {
          title: "商品标签",
          dataIndex: "tag",
          key: "tag",
          scopedSlots: { customRender: "tag" },
        },

        {
          title: "操作",
          dataIndex: "operation",
          with: 120,
          fixed: "right",
          scopedSlots: { customRender: "operation" },
        },
      ],
      formData: {
        tags: [],
      },
      goodsTabsList: [
        // 商品标签

        { label: "中文", value: "CHINESE", checked: false },
        { label: "数学", value: "MATH", checked: false },
        { label: "英文", value: "ENGLISH", checked: false },
        { label: "新生报班", value: "NEW_STU_REPORT_CLASS", checked: false },
        { label: "新生报班", value: "NEW_STU_REPORT_CLASS", checked: false },
        { label: "新生报班(86)", value: "新生报班(86)", checked: false },
        {
          label: "新生报班（English）",
          value: "NEW_STU_REPORT_CLASS_EN",
          checked: false,
        },
        { label: "续费", value: "RENEW", checked: false },
        { label: "补升", value: "MAKE_UP", checked: false },
        { label: "赠课", value: "HANDSEL_COURSE", checked: false },
        { label: "L系列悟空宝盒", value: "L_BOX", checked: false },
        { label: "G系列悟空宝盒", value: "G_BOX", checked: false },
        { label: "S系列悟空宝盒", value: "S_BOX", checked: false },
        { label: "团购", value: "BULK", checked: false },
        { label: "专题课", value: "SPECIAL_CLASS", checked: false },
        { label: "拓科", value: "TUO_KO", checked: false },
      ],
      isConfirmLoading: false,
      isVisible: false,
      checked1: false,
      checked2: false,
      checked3: false,
      studentProfile: session.get("studentProfile"),
    };
  },
  methods: {
    handleCurrency() {
      const { country } = this.studentProfile;
      this.currencyList.forEach((ele) => {
        if (country === "New Zealand" && ele.value !== "NZD") {
          // 新西兰只能选择nzd
          ele.checked = true;
          this.currencyType = "NZD";
        } else if (country === "United States" && ele.value !== "USD") {
          // 美国支持USD
          ele.checked = true;
          this.currencyType = "USD";
        } else if (country === "Australia" && ele.value !== "AUD") {
          // 澳大利亚支持AUD
          ele.checked = true;
          this.currencyType = "AUD";
        } else if (
          country !== "New Zealand" &&
          country !== "United States" &&
          country !== "Australia" &&
          ele.value !== "USD"
        ) {
          ele.checked = true;
          this.currencyType = "USD";
        }
      });
    },
    backBefore() {
      this.submitFlag = false;
    },
    // 计算总价
    handelTotal() {
      let total = 0;
      this.checkListGoods.map((ele) => {
        total += Number(ele?.subtotal);
      });
      let value = total + Number(this.freight) - this.checkedCouponNominalValue;
      value = value > 0 ? value : 0;
      return value.toFixed(2);
    },
    // 计算总税
    handleTaxAmount() {
      let taxAmount = 0;
      this.checkListGoods.map((ele) => {
        taxAmount += Number(ele?.tax);
      });
      return taxAmount.toFixed(2);
    },
    handleChangeTags(data, index) {
      this.orderTabsList.map((ele) => {
        ele.checked = false;
      });
      this.orderTabsList[index].checked = !data.checked;
    },
    // 二次确认订单
    handleConfirmSubmit() {
      const tags = this.orderTabsList
        .filter((ele) => ele.checked)
        .map((item) => item.value);
      const orderLines = this.checkListGoods.map((ele) => {
        return {
          productId: ele.uuid,
          quantity: ele.quantity,

          discount: ele.discounts,
        };
      });
      if (orderLines.length === 0) {
        return this.$message.error("请选择商品");
      }
      if (!tags.length) {
        return this.$message.error("请选择标签");
      }
      this.selTags = this.orderTabsList.filter((ele) => ele.checked);
      this.submitFlag = true;
      window.scrollTo(0, 0);
    },

    // 提交订单
    handleSubmit() {
      this.iconLoading = true;
      postReq("/api/ecommerce/order/submit", this.handleFormParams())
        .then((res) => {
          this.$message.success("订单创建成功");
          this.iconLoading = false;
          this.$router.push({
            path: "/orderDetail",
            query: { orderId: res.data.uuid },
          });
        })
        .catch((res) => {
          this.iconLoading = false;
        });
    },
    // 转换成树的结构
    transTotree(list, pid) {
      let treeList = [];
      // 根据list中pid来获取元素
      treeList = list.filter((item) => item.parentId === pid);
      treeList.forEach((item) => (item.preferential = this.transTotree(list, item.uuid)));
      return treeList;
    },
    // 去掉多余的参数
    getTree(ary) {
      return ary.map((v) => {
        const item = {
          productId: v.uuid,
          quantity: v.quantity,
          discount: v.discounts,
        };
        if (v.preferential && v.preferential.length)
          item.preferential = this.getTree(v.preferential);
        return item;
      });
    },
    handleFormParams() {
      const orderLinesArr = this.transTotree(this.checkListGoods);
      const orderLines = this.getTree(orderLinesArr);

      // const orderLines = this.checkListGoods.map((ele) => {
      //   // 改成树的结构
      //   return {
      //     productId: ele.uuid,
      //     quantity: ele.quantity,
      //     discount: ele.discounts,
      //   };
      // });
      const tags = this.orderTabsList
        .filter((ele) => ele.checked)
        .map((item) => item.value);
      return {
        fare: this.freight,
        currency: this.currencyType, // 币种
        subject: this.subject || "CHINESE",
        orderLines,
        businessId: "57526073-055c-4f58-adb0-ba555ea9c8b2",
        merchantId: "2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea",
        orderData: {
          whoCreated: `${this.userInfo.fullName} (${this.userInfo.email})`,
          countryEnName: this.studentProfile.country,
        },
        tags,
        orderShipment: {
          instruction: this.orderRemark,
          receiverEmail: this.studentProfile.email,
          receiverName: this.studentProfile.fullName,
          receiverPhone: this.studentProfile.mobile,
          studentCode: this.studentProfile.code,
          timeZone: this.studentProfile.timezoneStd,
        },
        expireTime: this.getExpiredTimeISO(this.expireTime),
        couponId: this.couponId,
      };
    },
    getExpiredTimeISO(minute) {
      return new Date(
        new Date().getTime() + minute * 60000 + 8 * 60 * 60000
      ).toISOString();
    },
    // 计算税
    handleTax(data, index) {
      let tax = 0;
      if (this.currencyType === "NZD") {
        tax = data.quantity * data.price * ((100 - data.discounts) / 100) * 0.15;
      }
      this.checkListGoods[index].tax = tax;
      return Math.round(tax * 100) / 100;
    },
    // 计算小计
    handleSubtotal(data, index) {
      const subtotal = data?.hasCurrency
        ? data.quantity * data.price * ((100 - data.discounts) / 100)
        : 0;
      this.checkListGoods[index].subtotal = subtotal;
      return Math.round(subtotal * 100) / 100;
    },
    // 表格里面的编辑
    onCellChange(index, dataIndex, value) {
      this.checkListGoods[index][dataIndex] = value;
      // 计算课时有效期延长多少个月
      if (dataIndex === "quantity") {
        this.periodGoodsFun();
      }
    },
    // 计算课包有效期
    periodGoodsFun() {
      let subject = "";
      let num = 0;
      let subjectC = "";
      let subjectM = "";
      let subjectE = "";
      this.delayMonth = 0;
      this.checkListGoods.map((parentItem) => {
        parentItem.items.map((subItem) => {
          subItem?.item?.specification.map((subItem1) => {
            // C001代表中文.M001代表数学
            if (subItem1.value === "C001") {
              // 数量乘以包里面的数量
              num += parseInt(parentItem.quantity) * subItem.quantity;
              subject = "CHINESE";
              subjectC = "CHINESE";
            } else if (subItem1.value === "M001") {
              num += parseInt(parentItem.quantity) * subItem.quantity;
              subject = "MATH";
              subjectM = "MATH";
            } else if (subItem1.value === "E001") {
              num += parseInt(parentItem.quantity) * subItem.quantity;
              subject = "ENGLISH";
              subjectE = "ENGLISH";
            }
          });
        });
      });
      // 一个订单里不能包含两个科目的商品
      if (subjectM && subjectC && subjectE) {
        this.$message.warning("一个订单里不能包含两个科目的商品");
        return false;
      }
      if (subject) {
        this.subject = subject;
        this.extendedValidity(subject, num);
      }
    },
    handleBlur(index, type) {
      this.checkListGoods[index][type] = true;
    },
    handleGoodsOk() {
      this.isVisible = false;
      this.handleGoodsPrice();
    },
    // 添加组合商品名称
    connectName(arr, str) {
      arr.map((item) => {
        console.log(item);
        if (item.preferential && item.preferential.length) {
          item.preferential.map((item1) => {
            item1.sku.parentName = item.name;
            item1.sku.parentId = item.uuid;
          });
          this.connectName(item.preferential);
        }
      });
      return arr;
    },

    // 添加商品计算商品价格
    handleGoodsPrice() {
      // 处理组合商品名字，给每一层级加上父名字跟ID
      this.connectName(this.listOfData);
      const goodsMap = {};
      const addFunc = (item) => {
        const price = item.prices.find((p) => p.currency === this.currencyType);
        const connectId = item.parentId ? `${item.uuid}${item.parentId}` : item.uuid;
        goodsMap[connectId] = {
          ...item,
          hasCurrency: !!price,
          quantity: item.quantity || 1,
          price: item.price || price?.value,
          discounts:
            item.discounts ||
            item.defaultDiscounts.find((ele) => ele.currency === this.currencyType)
              ?.percent ||
            0,
          tax: item.tax || 1,
          subtotal: item.subtotal || 0,
          productId: item.uuid,
          editQuantity: item.editQuantity || false,
          edit: item.edit || false,
          old: item.old || false,
        };
      };
      const skuIds = this.checkListGoods.map((m) => m.uuid);

      this.checkListGoods.forEach(addFunc);
      const newGoods = this.listOfData.filter((d) => !skuIds.includes(d.parentId));

      newGoods.forEach(addFunc);
      newGoods
        .flatMap((m) => m.preferential)
        .map((m) => {
          return {
            ...m.sku,
            quantity: m.quantity,
            quantitySub: m.quantity, // 用于单课包数量展示
          };
        })
        .forEach(addFunc);

      this.formData.tabs = [];
      this.checkListGoods = Object.values(goodsMap);
      this.listOfData = [];
      this.periodGoodsFun();
    },
    // 添加商品计算商品价格
    // 选择商品
    onItemChecked(event, index) {
      this.inventoryPage.content[index].checked = event.target.checked;
      this.inventoryPage.content[index].disabled = true;
      if (event.target.checked) {
        this.listOfData.push(this.inventoryPage.content[index]);
      } else {
        this.listOfData = this.listOfData.filter(
          (ele) => this.inventoryPage.content[index].uuid !== ele.uuid
        );
      }
      this.handelFilterChecked();
    },
    // 过滤已选择商品
    handelFilterChecked() {
      const checkList = this.inventoryPage.content.filter((item) => {
        return item.checked;
      });

      return checkList.length;
    },
    addProduct() {
      this.isVisible = true;
      this.onGetInventoryList();
    },

    // 获取库存记录数据
    onGetInventoryList(act) {
      if (act === "search") {
        this.initPagination.current = 1;
      }
      this.inventoryPage.busy = true;
      postReq(
        `/api/ecommerce/order/product/search/${this.initPagination.current}`,
        this.formData
      ).then((res) => {
        let list = res.data.content || [];
        list = list.map((ele) => {
          ele.checked = false;
          return ele;
        });

        this.listOfData.forEach((item) => {
          list.forEach((ele) => {
            if (item.uuid === ele.uuid) {
              ele.checked = true;
            }
          });
        });
        this.inventoryPage.content = JSON.parse(JSON.stringify(list)).map((sku) => {
          return {
            ...sku,
            // 修改商品是否可以选择,子商品可以在选择
            disabled:
              this.checkListGoods.find((g) => {
                // 没有子元素
                if (!g.parentId) {
                  return g.uuid === sku.uuid;
                }
                return g.parentId === sku.uuid;
              }) ||
              this.listOfData.find((g) => {
                // 没有子元素
                if (!g.parentId) {
                  return g.uuid === sku.uuid;
                }
                return g.parentId === sku.uuid;
              }) ||
              sku.tags.includes("NEW_STU_REPORT_CLASS_EN") ||
              sku.tags.includes("NEW_STU_REPORT_CLASS") ||
              sku.tags.includes("新生报班(86)") ||
              sku.tags.includes("TUO_KO"),
          };
        });

        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize = res.data.pageSize;
        this.initPagination.current = res.data.currentPage;
        this.inventoryPage.busy = false;
      });
    },
    handleTableChange(data) {
      this.initPagination.current = data.current;

      this.onGetInventoryList();
    },
    // 计算课时延长有效期
    extendedValidity(subject, num) {
      getReq(`/api/student/course_unit/config_expire/${subject}/${num}`).then((res) => {
        this.delayMonth = res.data.content;
      });
    },

    handleCancel() {
      this.isVisible = false;
    },
    initOptionsType(value, typeArr) {
      let name = "";
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    // 生成商品标签
    onTagsData(data) {
      return data.length > 0
        ? this.goodsTabsList
            .filter((ele) => data.includes(ele.value))
            .map((ele) => ele.label)
        : [];
    },
    // 删除商品
    handelDeleteItem(item) {
      this.listOfData = this.listOfData.filter((ele) => ele.uuid !== item.uuid);
      this.inventoryPage.content.map((ele) => {
        if (item.uuid === ele.uuid) {
          ele.checked = false;
          ele.disabled = false;
        }
      });
    },
    // 删除元素
    deleteItem(index) {
      this.listOfData.splice(index, 1);
      this.checkListGoods.splice(index, 1);
      this.periodGoodsFun();
    },
    async handleGetCoupons() {
      this.couponOptions = [];
      this.couponId = undefined;
      const { data } = await getCoupons({
        studentCode: this.studentProfile.code,
        currency: this.currencyType,
      });
      this.couponOptions = (data || []).map((item) => ({
        label: item.deductibleAmount + "代金券",
        value: item.uuid,
        deductibleAmount: item.deductibleAmount,
      }));
    },
  },
  created() {
    this.handleCurrency();
  },
};
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
<style lang="less" scoped>
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  margin-bottom: 16px;
}
.order-content {
  padding: 18px 20px;
  background-color: #fff;
  min-height: calc(100vh - 100px);
}
.order-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
}
.order-title::before {
  content: "";
  display: block;
  float: left;
  margin: 4px 6px 0 0;
  width: 3px;
  height: 16px;
  background-color: #41cc83;
  border-radius: 0px 2px 2px 0px;
}
.student-info {
  padding-left: 100px;
}

/deep/ .ant-tag-checkable {
  border-color: #d9d9d9;
}
.order-label {
  padding-right: 8px;
  position: relative;
}
.order-label::before {
  position: absolute;
  top: 50%;
  transform: translate(-10px, -50%);
  display: flex;
  content: "*";
  color: red;
  font-size: 14px;
}
.info-txt {
  color: rgb(102, 102, 102);
  padding-left: 5px;
}
.check-list {
  width: 200px;
  &::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border: 15px solid #ddd;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    left: -20px;
    top: 10px;
  }
  position: relative;

  margin-left: 50px;
  background-color: #efefef;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.other-wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 20px;
  background-color: #fafafa;
}
.right-item {
  margin-top: 12px;
  text-align: right;
}
.right-label {
  width: 50px;
  display: inline-block;
  margin-right: 10px;
}
.submit-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit-txt {
  width: 600px;
  color: red;
}
.left-wrap {
  min-width: 300px;
  flex: 1;
}
</style>
